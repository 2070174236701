// import PropTypes from 'prop-types';
import Button from '../Button/Button';
import styles from './CtaSlim.module.scss';

const CtaSlim = ({ title = '', link = {} }) => {
    if (!link.href && !link.title) {
        return;
    }

    return (
        <div className={styles['CtaSlim']}>
            <div className={styles['CtaSlim__Container']}>
                <a className={styles['CtaSlim__Link']} href={link.href}>
                    <span className={'sr-only'}>{link.title}</span>
                </a>
                <h2 className={styles['CtaSlim__Title']}>{title}</h2>
                <div className={styles['CtaSlim__ButtonContainer']}>
                    <span className={styles['CtaSlim__Button']}>
                        {link.title}
                    </span>
                </div>
            </div>
        </div>
    );
};

CtaSlim.propTypes = {};

export default CtaSlim;
