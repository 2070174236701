// import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ucFirst } from '../../utils/caseconverters';

import Icon from '../Icon/Icon';

import styles from './Button.module.scss';

const Button = ({ icon = '', link = {}, modifiers = [] }) => {
    const { title, href } = link;
    if (!title || !href) {
        return;
    }

    const classes = classNames(
        styles['Button'],
        modifiers.map((modifier) => styles['Button--' + ucFirst(modifier)])
    );

    return (
        <a className={classes} href={href}>
            {!!icon && <Icon icon={icon} />}
            {title}
        </a>
    );
};

Button.propTypes = {};

export default Button;
