// import PropTypes from 'prop-types';
import styles from './Logo.module.scss';

const Logo = ({ dimensions = {} }) => {
    return (
        <svg
            fill="none"
            viewBox="0 0 120 40"
            xmlns="http://www.w3.org/2000/svg"
            className={styles['Logo']}
            style={
                dimensions
                    ? {
                          maxWidth: dimensions.width,
                          maxHeight: dimensions.height,
                      }
                    : ''
            }>
            <g fill="currentColor">
                <path
                    clipRule="evenodd"
                    d="m110.517 16.5093c-1.007.3546-2.097 1.3718-2.097 3.8064v19.3439h-6.197v-19.3439c0-4.6663 2.395-8.2916 6.231-9.6436 3.678-1.29643 7.913-.2308 11.279 2.6512l-4.033 4.6998c-2.057-1.7605-4.02-1.9241-5.183-1.5138z"
                    fillRule="evenodd"
                />
                <path d="m0 .668522 6.13553-.668522v39.6657h-6.13553z" />
                <path d="m15.1144 27.8552c.1488.8913.4462 1.727.8925 2.5069.4834.7428 1.0783 1.4113 1.7848 2.0056.7065.5571 1.4874 1.0028 2.3427 1.337.8924.3343 1.8407.5014 2.8446.5014 1.4131 0 2.6588-.2228 3.7371-.6685 1.1156-.4457 1.9709-1.1142 2.5658-2.0056h7.3069c-.4834 1.2628-1.1714 2.4141-2.0638 3.4541-.8553 1.0399-1.8778 1.9313-3.0678 2.6741-1.1899.7428-2.51 1.3184-3.9602 1.727-1.4502.4085-2.9562.6128-4.518.6128-2.0079 0-3.8858-.3714-5.6335-1.1142s-3.2723-1.7456-4.5737-3.0084c-1.2643-1.2999-2.2683-2.8226-3.01203-4.5682-.7437-1.7456-1.11556-3.6026-1.11556-5.571 0-1.9685.35326-3.8255 1.05978-5.5711.74371-1.7456 1.74771-3.2497 3.01201-4.5125 1.3015-1.2999 2.8074-2.3213 4.518-3.0641 1.7477-.7428 3.6069-1.1142 5.5777-1.1142s3.8115.3714 5.522 1.1142c1.7477.7428 3.2537 1.7642 4.518 3.0641 1.3015 1.2628 2.3055 2.7669 3.012 4.5125.7437 1.7456 1.1155 3.6026 1.1155 5.5711 0 .7428-.0558 1.4484-.1673 2.117zm7.6973-10.6964c-.8924 0-1.7477.1485-2.5658.4457-.7808.2599-1.5059.6313-2.1753 1.1142-.6321.4456-1.1899 1.0027-1.6733 1.6713-.4462.6314-.7809 1.2999-1.004 2.0055h14.8368c-.2231-.7056-.5763-1.3741-1.0597-2.0055-.4463-.6686-1.004-1.2257-1.6734-1.6713-.6321-.4829-1.3572-.8543-2.1753-1.1142-.7809-.2972-1.6175-.4457-2.51-.4457z" />
                <path d="m41.4096 11.8106 6.6933 19.1643 6.6933-19.1643h6.5818l-10.2631 27.8551h-6.024l-10.263-27.8551z" />
                <path d="m62.6234.668522 6.1355-.668522v19.8329 19.8328h-6.1355z" />
                <path d="m77.7378 27.8552c.1487.8913.4462 1.727.8924 2.5069.4835.7428 1.0784 1.4113 1.7849 2.0056.7065.5571 1.4874 1.0028 2.3427 1.337.8924.3343 1.8406.5014 2.8446.5014 1.4131 0 2.6588-.2228 3.7371-.6685 1.1156-.4457 1.9708-1.1142 2.5658-2.0056h7.3069c-.4834 1.2628-1.1714 2.4141-2.0638 3.4541-.8553 1.0399-1.8778 1.9313-3.0678 2.6741-1.1899.7428-2.51 1.3184-3.9602 1.727-1.4502.4085-2.9562.6128-4.518.6128-2.008 0-3.8858-.3714-5.6335-1.1142s-3.2723-1.7456-4.5738-3.0084c-1.2643-1.2999-2.2682-2.8226-3.0119-4.5682-.7438-1.7456-1.1156-3.6026-1.1156-5.571 0-1.9685.3533-3.8255 1.0598-5.5711.7437-1.7456 1.7477-3.2497 3.012-4.5125 1.3014-1.2999 2.8074-2.3213 4.518-3.0641 1.7476-.7428 3.6069-1.1142 5.5777-1.1142s3.8115.3714 5.522 1.1142c1.7477.7428 3.2537 1.7642 4.518 3.0641 1.3014 1.2628 2.3054 2.7669 3.012 4.5125.7437 1.7456 1.1155 3.6026 1.1155 5.5711 0 .7428-.0558 1.4484-.1673 2.117zm7.6973-10.6964c-.8924 0-1.7477.1485-2.5658.4457-.7808.2599-1.506.6313-2.1753 1.1142-.6321.4456-1.1899 1.0027-1.6733 1.6713-.4462.6314-.7809 1.2999-1.004 2.0055h14.8368c-.2231-.7056-.5764-1.3741-1.0598-2.0055-.4462-.6686-1.0039-1.2257-1.6733-1.6713-.6321-.4829-1.3572-.8543-2.1753-1.1142-.7809-.2972-1.6176-.4457-2.51-.4457z" />
            </g>
        </svg>
    );
};

Logo.propTypes = {};

export default Logo;
