// import PropTypes from 'prop-types';
import Marquee from 'react-fast-marquee';
import styles from './MarqueeText.module.scss';

const MarqueeText = ({ text = '' }) => {
    const hasText = text !== '';

    if (!hasText) {
        return;
    }

    return (
        <div className={styles['MarqueeText']}>
            <Marquee autoFill={true} speed={110}>
                <span className={styles['MarqueeText__Text']}>{text}</span>
            </Marquee>
        </div>
    );
};

Marquee.propTypes = {};

export default MarqueeText;
