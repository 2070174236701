import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { ucFirst } from '../../utils/caseconverters';

import styles from './Icon.module.scss';

const Apple = dynamic(() => import('./Icons/IconApple'));
const ArrowLeft = dynamic(() => import('./Icons/IconArrowLeft'));
const ArrowRight = dynamic(() => import('./Icons/IconArrowRight'));
const ArrowDown = dynamic(() => import('./Icons/IconArrowDown'));
const ChevronDown = dynamic(() => import('./Icons/IconChevronDown'));
const ChevronLeft = dynamic(() => import('./Icons/IconChevronLeft'));
const ChevronRight = dynamic(() => import('./Icons/IconChevronRight'));
const ChevronUp = dynamic(() => import('./Icons/IconChevronUp'));
const Close = dynamic(() => import('./Icons/IconClose'));
const Download = dynamic(() => import('./Icons/IconDownload'));
const GooglePlay = dynamic(() => import('./Icons/IconGooglePlay'));
const Minus = dynamic(() => import('./Icons/IconMinus'));
const Pause = dynamic(() => import('./Icons/IconPause'));
const Play = dynamic(() => import('./Icons/IconPlay'));
const PlayOutline = dynamic(() => import('./Icons/IconPlayOutline'));
const Plus = dynamic(() => import('./Icons/IconPlus'));

export const allIcons = {
    apple: Apple,
    arrowDown: ArrowDown,
    arrowLeft: ArrowLeft,
    arrowRight: ArrowRight,
    chevronDown: ChevronDown,
    chevronLeft: ChevronLeft,
    chevronRight: ChevronRight,
    chevronUp: ChevronUp,
    close: Close,
    download: Download,
    googlePlay: GooglePlay,
    minus: Minus,
    pause: Pause,
    play: Play,
    playOutline: PlayOutline,
    plus: Plus,
};

const Icon = ({ icon = '', modifiers = [], dimensions = {} }) => {
    if (!icon) {
        return;
    }

    const Svg = allIcons[icon];

    const classes = classNames(
        styles['Icon'],
        styles['Icon--' + ucFirst(icon)],
        modifiers.map((modifier) => styles['Icon--' + ucFirst(modifier)])
    );

    return (
        <span
            className={classes}
            style={
                dimensions
                    ? {
                          width: dimensions.width,
                          height: dimensions.height,
                      }
                    : ''
            }>
            {!!Svg && <Svg />}
        </span>
    );
};

Icon.propTypes = {
    icon: PropTypes.oneOf(Object.keys(allIcons)),
    modifiers: PropTypes.array,
    dimensions: PropTypes.shape({
        width: PropTypes.string,
        height: PropTypes.string,
    }),
};

export default Icon;
