// import PropTypes from 'prop-types';
import React, { useEffect, useId, useState } from 'react';
import { setCookie, getCookie } from 'cookies-next';
import { useTranslation } from 'next-i18next';

import classNames from 'classnames';
import md5 from 'blueimp-md5';

import Icon from '../Icon/Icon';
import styles from './Banner.module.scss';

const Banner = ({ html = '', backgroundColor = '', activeBanner = false }) => {
    const { t } = useTranslation();
    const id = useId();

    const [isHidden, setHidden] = useState(false);

    const cookieValue = getCookie('banner');

    if (!activeBanner) {
        return;
    }

    const DAYS_365_IN_MILLISECONDS = 60 * 60 * 24 * 365;

    const content = JSON.stringify([html, backgroundColor]);
    const hashContent = md5(content);

    const shouldHideBanner = cookieValue === hashContent || isHidden;

    if (shouldHideBanner) {
        return;
    }

    const handleClick = () => {
        setHidden(true);
        setCookie('banner', hashContent, { maxAge: DAYS_365_IN_MILLISECONDS });
    };

    const classes = classNames(styles['Banner'], {
        [styles['Banner--Visible']]: !shouldHideBanner,
    });

    return (
        <div
            id={id}
            className={classes}
            aria-hidden={isHidden}
            style={{
                backgroundColor: !!backgroundColor
                    ? backgroundColor
                    : '#000000',
            }}>
            <div className={styles['Banner__Container']}>
                <div
                    className={styles['Banner__Content']}
                    dangerouslySetInnerHTML={{ __html: html }}
                />
                <button
                    className={styles['Banner__Close']}
                    onClick={() => handleClick()}
                    aria-expanded={!isHidden}
                    aria-controls={id}>
                    <Icon
                        icon={'close'}
                        dimensions={{ width: '16px', height: '16px' }}
                    />
                    <span className={'sr-only'}>{t('Banner.closeBanner')}</span>
                </button>
            </div>
        </div>
    );
};

Banner.propTypes = {};

export default Banner;
